.footer-widget {
    margin-bottom: 0 !important;

    &,
    .widget {
        padding: 0;
        border: none;
        padding-bottom: 0;
        background-color: transparent;
        box-shadow: none;
    }

    .wp-block-search__label,
    .widget_title {
        color: $white-color;
        border-bottom: none;
        margin: -0.04em 0 40px 0;
        position: relative;

        &:after {
            display: none;
        }
    }

    &.widget_meta,
    &.widget_pages,
    &.widget_archive,
    &.widget_recent_entries,
    &.widget_categories,
    &.widget_nav_menu {

        .menu,
        >ul {
            margin-bottom: -5px;
        }

        a {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 25px;
            padding: 0;
            font-family: $title-font;
            color: $body-color;
            display: block;
            max-width: 100%;
            width: max-content;
            background-color: transparent;
            border: none;
            position: relative;

            &:hover {
                background-color: transparent;
                color: $theme-color2;
            }
        }

        li {
            border: 0 !important;
            padding-bottom: 0 !important;

            &:last-child {
                a {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.widget-about {
    .footer-logo {
        margin-bottom: 37px;
    }

    .about-text {
        max-width: 350px;
        margin-top: -0.65em;
        margin-bottom: 27px;
    }
}

.widget-contact {
    margin-top: -0.5em;

    p {
        color: $body-color;
        font-size: 18px;
        font-weight: 400;
        font-family: $title-font;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        a {
            color: $body-color;

            &:hover {
                color: $theme-color;
            }
        }
    }
}

.widget-contact-grid {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    &:not(:last-child) {
        margin-bottom: 30px;
    }

    .icon {
        margin-top: -3px;
    }

    i {
        color: $theme-color2;
    }

    p {
        font-size: 18px;
        font-weight: 400;
        font-family: $title-font;
        color: $white-color;
        margin-bottom: 0;
        margin-top: -0.3em;
    }

    h6 {
        font-size: 18px;
        font-weight: 400;
        color: $white-color;
        margin-top: -0.3rem;
        margin-bottom: -0.3em;

        a {
            color: $white-color;

            &:hover {
                color: $theme-color2;
            }
        }
    }
}

.widget-newsletter {
    background: rgba($color: #ffffff, $alpha: 0.04);
    border-radius: 5px;
    padding: 35px 40px;
    max-width: 410px;
}

.footer-text {
    margin-bottom: 16px;
    color: $body-color;
    font-size: 18px;
    font-weight: 400;
    font-family: $title-font;
    text-align: center;
}


.newsletter-form {
    position: relative;

    @include xs {
        margin: 40px 0 80px 0;
    }
    &.style2 {
        .form-group {
            border: 1px solid rgba($color: #ffffff, $alpha: 1);
        }
    }
    &.style3 {
        @include xs {
            margin: 0px 0 80px 0;
        }

        .form-group {
            border: 1px solid rgba($color: #ffffff, $alpha: 0.10);
            height: 60px;
            border-radius: 170px;
            input {
                height: 60px;
                padding-left: 30px;
            }
            @include xs {
                height: 60px;
            }
        }

        .global-btn {
            width: 60px;
            height: 60px;
            top: 0px;
            right: 0px;
            background: $theme-color;
            color: $white-color;
            &:hover {
                color: $theme-color;
            }
            @include xs {
                width: 100%;
                top: 15px;
                right: 0;
            }

            @include vxs {
                top: 20px;
            }
        }
    }


    .form-group {
        position: relative;
        display: flex;
        gap: 15px;
        height: 80px;
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 45px;
        margin: 0;

        @include xs {
            display: block;
            height: 60px;
        }

        input {
            background-color: transparent;
            border: 0;
            padding: 0;
            width: 100%;
            height: 80px;
            padding-left: 40px;

            @include inputPlaceholder {
                color: $white-color;

            }

            @include xs {
                height: 60px;
            }
        }

        .form-control:focus {
            color: $white-color;
        }
    }

    .global-btn {
        width: 188px;
        height: 60px;
        line-height: 60px;
        min-width: auto;
        // padding: 22px 30px;
        // text-transform: capitalize;
        background-color: $theme-color2;
        // position: absolute;
        top: 10px;
        right: 10px;
        font-size: 16px;
        color: $title-color;
        border-radius: 45px;
        display: flex;
        justify-content: center;
        flex: none;
        padding: 0;


        &:before,
        &:after {
            background-color: $white-color;
        }

        @include xs {
            position: relative;
            display: block;
            width: 100%;
            right: 0;
        }
    }
}




@include sm {
    .footer-widget {
        .widget_title {
            margin-bottom: 35px;
        }
    }

    .footer-widget.widget_meta a,
    .footer-widget.widget_pages a,
    .footer-widget.widget_archive a,
    .footer-widget.widget_recent_entries a,
    .footer-widget.widget_categories a,
    .footer-widget.widget_nav_menu a {
        font-size: 16px;
        margin-bottom: 20px;
    }
}

@include xs {
    .widget-newsletter {
        padding: 30px;
        max-width: 410px;
    }
}