/*=================================
    CSS Index Here
==================================*/
/*

01. Theme Base
    1.1. Mixin
    1.2. Function
    1.3. Variable
    1.4. Typography
    1.5. Extend
    1.7. Wordpress Default
02. Reset
    2.1. Container
    2.2. Grid
    2.3. Input
    2.4. Slick Slider
    2.5. Mobile Menu
03. Utilities
    3.1. Preloader
    3.2. Buttons
    3.3. Titles
    3.4. Common
    3.6. Font
    3.7. Background
    3.8. Text Color
    3.9. Overlay
    3.10. Animation
    
04. Template Style
    4.1. Widget
    4.2. Header
    4.3. Footer
    4.4. Breadcumb
    4.5. Pagination
    4.6. Blog
    4.7. Comments
    4.8. Hero Area
    4.9. Error    
    4.00. Popup Search
    4.00. Popup Side Menu
    4.00. Wocommerce
    4.00. Subscribe
    4.00. Cart
    4.00. Checkout
    4.00. Wishlist
    4.00. Contact
    4.00. About
    4.00. Team
    4.00. Testimonial
    4.00. Counter
    4.00. Client
    4.00. Simple Sections
    4.00. Video
    4.00. Category
    4.00. Faq
    4.00. feature
    4.00. CTA
    4.00. Service
    4.00. Pricing
    4.00. Why choose Us
    4.00. Project
    4.00. Event
    4.00. Gallery
    4.00. Schedule

05. Spacing

*/
/*=================================
    CSS Index End
==================================*/

/*=================================
   01. Theme Base
==================================*/
/*------------------- 1.1. Mixin -------------------*/
@import "base/mixin";

/*------------------- 1.2. Function -------------------*/
@import "base/function";

/*------------------- 1.3. Variable-------------------*/
@import "base/variable";

/*------------------- 1.5. Typography -------------------*/
@import "base/typography";

/*=================================
    02. Reset
==================================*/
/*------------------- 2.1. Container -------------------*/
@import "reset/container";

/*------------------- 2.2. Grid -------------------*/
@import "reset/grid";

/*------------------- 2.3. Input -------------------*/
@import "reset/input";

/*------------------- 2.4. Slick Slider -------------------*/
@import "reset/slick-slider";

/*------------------- 2.5. Mobile Menu -------------------*/
@import "reset/mobile-menu";

/*=================================
    03. Utilities
==================================*/
/*------------------- 3.1. Preloader -------------------*/
@import "utilities/preloader";

/*------------------- 3.2. Buttons -------------------*/
@import "utilities/btns";

/*------------------- 3.3. Titles -------------------*/
@import "utilities/titles";

/*------------------- 3.4. Common -------------------*/
@import "utilities/common";

/*------------------- 3.6. Font -------------------*/
@import "utilities/font";

/*------------------- 3.7. Background -------------------*/
@import "utilities/background";

/*------------------- 3.8. Text Color -------------------*/
@import "utilities/text-color";

/*------------------- 3.9. Overlay -------------------*/
@import "utilities/overlay";

/*------------------- 3.10. Animation -------------------*/
@import "utilities/animation";

/*------------------- 3.10. Animation -------------------*/
@import "utilities/extra";

/*=================================
    04. Template Style
==================================*/
/*------------------- 4.1. Widget  -------------------*/
@import "template/widgets/widget-default-list";
@import "template/widgets/widget-sidebar";
@import "template/widgets/widget-footer";

/*------------------- 4.2. Header  -------------------*/
@import "template/headers/header";

/*------------------- 4.3. Footer  -------------------*/
@import "template/footers/footer";

/*------------------- 4.4. Breadcumb  -------------------*/
@import "template/breadcumb/breadcumb-v1";

/*------------------- 4.5. Pagination  -------------------*/
@import "template/sections/pagination-v1";

/*------------------- 4.6. Blog  -------------------*/
@import "template/sections/blog";

/*------------------- 4.7. Comments  -------------------*/
@import "template/sections/comments";

/*------------------- 4.8. Hero Area  -------------------*/
@import "template/sections/hero";

/*------------------- 4.9. Error  -------------------*/
@import "template/sections/error";

/*------------------- 4.00. Popup Search  -------------------*/
@import "template/sections/popupsearch-v1";

/*------------------- 4.00. Popup Side Menu  -------------------*/
@import "template/sections/sidemenu-v1";

/*------------------- 4.00. Subscribe  -------------------*/
@import "template/sections/subscribe";

/*------------------- 4.00. Contact  -------------------*/
@import "template/sections/contact";

/*------------------- 4.00. About  -------------------*/
@import "template/sections/about";

/*------------------- 4.00. Team  -------------------*/
@import "template/sections/team";

/*------------------- 4.00. Testimonial  -------------------*/
@import "template/sections/testimonial";

/*------------------- 4.00. Counter  -------------------*/
@import "template/sections/counter";

/*------------------- 4.00. Blog  -------------------*/
@import "template/sections/blog-extra";

/*------------------- 4.00. Client  -------------------*/
@import "template/sections/client";

/*------------------- 4.00. Simple Sections  -------------------*/
@import "template/sections/simple-sections";

/*------------------- 4.00. Video -------------------*/
@import "template/sections/video";

/*------------------- 4.00. Category Menu -------------------*/
@import "template/sections/category";

/*------------------- 4.00. Faq -------------------*/
@import "template/sections/faq";

/*------------------- 4.00. feature -------------------*/
@import "template/sections/feature";

/*------------------- 4.00. CTA -------------------*/
@import "template/sections/cta";

/*------------------- 4.00. Service -------------------*/
@import "template/sections/service";

/*------------------- 4.00. Why Choose Us -------------------*/
@import "template/sections/wcu";

/*------------------- 4.00. Pricing -------------------*/
@import "template/sections/pricing";

/*------------------- 4.00. Portfolio -------------------*/
@import "template/sections/portfolio";

/*------------------- 4.00. Marquee -------------------*/
@import "template/sections/marquee";

/*------------------- 4.00. Appointment -------------------*/
@import "template/sections/appointment";

/*------------------- 4.00. Process -------------------*/
@import "template/sections/process";

/*------------------- 4.00. Product -------------------*/
@import "template/sections/products";

/*------------------- 4.00. Cart -------------------*/
@import "template/sections/cart";

/*------------------- 4.00. Wishlist -------------------*/
@import "template/sections/wishlist";

/*=================================
    05. Spacing
==================================*/
@import "spacing/spacing";

/*=================================
    RTL
==================================*/
@import "rtl";

/*custom css*/
.cs-text-style-1 {
  font-size: 30px;
}

.feature-icon {
  max-width: 60px;
}

.wechat-qr-warp {
  position: relative;
  z-index: 10;
}

.header-info_link .wechat-qr-img,
.wechat-qr-warp .wechat-qr-img {
  top: 0;
  padding-top: 41px;
  position: absolute;
  width: 200px;
  max-width: 200px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.header-info_link .wechat-qr,
.wechat-qr-warp .wechat-qr-img img:not([draggable]).wechat-qr {
  box-shadow: 0 0 4px 0 #c3c3c3;
  border-radius: 5px;
}

.footer-wrapper .wechat-qr-warp .wechat-qr-img {
  bottom: 35px;
  top: auto;
}

.wechat-qr-warp:hover .wechat-qr-img {
  display: block;
}

.rfm-child img {
  max-width: 90px;
}

.tab-pane {
  height: 100%;
}

.tab-pane .goal-list_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.goal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.checklist ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.checklist {
  height: 100%;
}